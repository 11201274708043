import { Component } from '@angular/core';
import type { IBookInputNameToValueMap } from '@studiobuki/shared/dist/data/books/inputs/types';
import { Logger } from '@studiobuki/shared/dist/logger';
import Froebelkan from 'src/app/data/books/Froebelkan';
import { DiscountService } from '@studiobuki/web-core/lib/discount';
import { getBooksDiscount } from '@studiobuki/shared/dist/discount/utils';
import { MediaService } from '@studiobuki/web-core/lib/media';
import { faqDataCondensed } from 'src/app/data/faq/data';
import type { ICommonBookData } from '@studiobuki/shared/dist/models';
import {
  INPUT_NAME_AGE_TO_BE,
  INPUT_NAME_KIDS_BIRTHDAY,
  INPUT_NAME_KIDS_NAME,
  INPUT_NAME_KUN_CHAN,
} from '@studiobuki/shared/dist/book/constants';
import { Router } from '@angular/router';
import { stepsData } from 'src/app/data/steps/data';
import { ROUTE_PATH_FAQ } from '@studiobuki/web-core/lib/routing';
import { GAGetEvents, GATrack } from 'src/analytics';
import { ALIAS_TO_ID_MAP } from '@studiobuki/shared/dist/book/maps';
import { BookFroebelkanService } from '../book-page/pages/book-froebelkan-page/book-froebelkan.service';

const log = new Logger('HomePageComponent');

@Component({
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent {
  public readonly book = Froebelkan;

  public readonly getBooksDiscount = getBooksDiscount;

  public readonly faqDataCondensed = faqDataCondensed;

  public readonly sectionSteps = stepsData;

  public loading = false;

  constructor(
    public discountService: DiscountService,
    public mediaService: MediaService,
    private _bookFroebelkanService: BookFroebelkanService,
    private _router: Router,
  ) {}

  public async submitted(value: IBookInputNameToValueMap) {
    this.loading = true;

    log.info('submitted', value);
    // const { alias, gift } = this;

    const common: Partial<ICommonBookData> = {};

    // if (gift) {
    //   common.gift = gift;

    //   const userGift = await this._firebaseService.getUserGift(gift);

    //   if (checkUserGift(userGift)) {
    //     common.cover = userGift.book.cover;
    //   }
    // }

    const newBookId = await this._bookFroebelkanService.createBook(
      {
        // @ts-ignore
        heroName: value[INPUT_NAME_KIDS_NAME],
        // @ts-ignore
        kunChan: value[INPUT_NAME_KUN_CHAN],
        // @ts-ignore
        birthDate: value[INPUT_NAME_KIDS_BIRTHDAY],
        // @ts-ignore
        ageToBe: value[INPUT_NAME_AGE_TO_BE],
      },
      common,
    );

    log.info('created book', newBookId);

    await this._router.navigate(['book', newBookId], {
      // replaceUrl: true,
      // queryParams: {
      //   [ROUTE_QUERY_PARAM_GIFT]: gift,
      // },
    });

    log.info('submit', /* alias, */ value);

    const bookAliasId = ALIAS_TO_ID_MAP[this.book.alias];

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!bookAliasId) {
      log.error(`didn't manage to get bookAliasId by alias`, {
        alias: this.book.alias,
        bookAliasId,
      });
      return;
    }

    GATrack(GAGetEvents.getBookPreview(bookAliasId));
  }

  public onFaqButtonClick() {
    log.info('onFaqButtonClick');
    return this._router.navigate([ROUTE_PATH_FAQ]);
  }
}
