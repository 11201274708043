import type { OnDestroy, OnInit } from '@angular/core';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { environment } from 'src/environments/environment';
import capitalize from 'lodash/capitalize';
import { Logger } from '@studiobuki/shared/dist/logger';
import { getRegionByNameEnglish } from '@studiobuki/shared/dist/shipping/utils';
import type { BookInOrder, IAddress } from '@studiobuki/shared/dist/interfaces';
import { getFullName } from '@studiobuki/shared/dist/utils';
import type {
  TRegion,
  TShippingMethod,
} from '@studiobuki/shared/dist/shipping/interfaces';
import type { IShipping } from '@studiobuki/shared/dist/data/shipping/types';
import { FormEmailComponent } from '@studiobuki/web-core/lib/form-email';
import { FormStripeAddressComponent } from '@studiobuki/web-core/lib/form-stripe-address';
import { FormPaymentComponent } from '@studiobuki/web-core/lib/form-payment';
import type { IDiscountCampaign } from '@studiobuki/shared/dist/discount/interfaces';
import Subscriber from '@studiobuki/shared/dist/subscriber';
import { REGION_NAME_ENGLISH_DEFAULT } from '@studiobuki/shared/dist/shipping/constants';
import { DiscountService } from '@studiobuki/web-core/lib/discount';
import { getTotal } from '@studiobuki/web-core/lib/price';
import type { IFormData, IStep } from '../../interfaces';

const log = new Logger('MainComponent');

enum ESummary {
  email = 'メールアドレス',
  shippingAddress = 'お届け先情報',
  billingAddress = '請求先情報',
  shippingMethod = '配送方法',
}

const getSummaryAddress = (form: IAddress) => {
  const region = getRegionByNameEnglish(form.addressLine1);

  return `
    〒${form.postalCode}<br>
    ${region.name} ${form.addressLine2} ${form.addressLine3}<br>
    ${getFullName(form.familyName, form.givenName)}さま<br>
    ${form.phone}
  `;
};

@Component({
  selector: 'app-main[shippings][disabledShippings]',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit, OnDestroy {
  @Input() step!: IStep['id'];

  @Output() stepChange = new EventEmitter<IStep['id']>();

  @Input() books!: BookInOrder[];

  @Output() dataChange = new EventEmitter<IFormData>();

  @Input() disabledShippings!: TShippingMethod[];

  @Input() shippings!: IShipping[];

  @Input() set shipping(shipping: IShipping | undefined) {
    this.formShippingMethod = shipping?.id;
  }

  get shipping() {
    return this.shippings.find(({ id }) => id === this.formShippingMethod);
  }

  @Input() isBackDisabled = false;

  @Input() isNextDisabled = false;

  @Output() backClick = new EventEmitter<void>();

  @Output() nextClick = new EventEmitter<void>();

  @ViewChild('formEmailRef')
  public formEmailComponent?: FormEmailComponent;

  @ViewChild('formBillingAddressRef')
  public formBillingAddressComponent?: FormStripeAddressComponent;

  @ViewChild('formShippingAddressRef')
  public formShippingAddressComponent?: FormStripeAddressComponent;

  @ViewChild(FormPaymentComponent)
  public formPaymentComponent?: FormPaymentComponent;

  public formEmail: IFormData['formEmail'];

  public formBillingAddress: IFormData['formBillingAddress'];

  public formShippingAddress: IFormData['formShippingAddress'];

  public formSameBillingAddress: IFormData['formSameBillingAddress'] = true;

  public formShippingMethod: IFormData['formShippingMethod'];

  public formPayment: IFormData['formPayment'];

  public activeDiscountCampaign?: IDiscountCampaign | undefined;

  public readonly ESummary = ESummary;

  public readonly environment = environment;

  private readonly _sub = new Subscriber();

  // * summary getters
  get summaryEmail(): string {
    return this.formEmail?.email || '';
  }

  get summaryShippingAddress() {
    const form = this.formShippingAddress;

    return form ? getSummaryAddress(form) : '';
  }

  get summaryBillingAddress() {
    const form = this.formSameBillingAddress
      ? this.formShippingAddress
      : this.formBillingAddress;

    return form ? getSummaryAddress(form) : '';
  }

  get summaryShippingMethod(): string {
    const { shipping } = this;
    let str = '';

    if (shipping) {
      const { name, price, currency } = shipping;

      str = `${capitalize(name)} ・ ${getTotal(currency, price)} (税込)`;
    }

    return str;
  }

  get summaryShow(): boolean {
    return (
      !!(
        this.summaryEmail ||
        this.summaryBillingAddress ||
        this.summaryShippingAddress ||
        this.summaryShippingMethod
      ) && this.step !== 1
    );
  }

  get region(): TRegion {
    const form = this.formShippingAddress;

    if (form) {
      return getRegionByNameEnglish(form.addressLine1);
    }

    return getRegionByNameEnglish(REGION_NAME_ENGLISH_DEFAULT);
  }

  constructor(private _discountService: DiscountService) {}

  ngOnInit() {
    this._sub.push(
      this._discountService.activeDiscountCampaign$.subscribe(
        (discountCampaign) => {
          this.activeDiscountCampaign = discountCampaign;
        },
      ),
    );
  }

  ngOnDestroy() {
    this._sub.unsubscribe();
  }

  public onChange() {
    const data: IFormData = {
      formEmail: this.formEmail,
      formBillingAddress: this.formBillingAddress,
      formShippingAddress: this.formShippingAddress,
      formSameBillingAddress: this.formSameBillingAddress,
      formShippingMethod: this.formShippingMethod,
      formPayment: this.formPayment,
    };

    this.dataChange.emit(data);
  }

  public summaryOnChange(key: ESummary): void {
    log.info('change', key);
    this.stepChange.emit(2);
  }

  /**
   * filling the customer info form group with test data
   */
  // public fillCustomerInfo(
  //   data: PartialUndefined<IFormData['formBillingAddress']> = {},
  // ): void {
  //   this.formBillingAddressComponent.formGroup.setValue(
  //     Object.assign(
  //       this.formBillingAddressComponent.formGroup.value || {},
  //       data,
  //     ),
  //   );
  // }
}
