import { INPUT_VALUE_KUN_CHAN_NASHI } from '@studiobuki/shared/dist/book/constants';
import { KUNCHAN_VALUE_TO_LABEL_MAP } from '@studiobuki/shared/dist/book/maps';
import type { IBookHiraganaData } from '@studiobuki/shared/dist/models';
import {
  book6SymbolsMapper,
  book6SymbolsMapperMapValues,
} from '@studiobuki/shared/dist/utils';
import type { ImageElement } from '@studiobuki/web-core/lib/book-common';
import {
  KEY_KIDS_TITLE,
  KEY_WITHOUT_SYMBOL,
  KEY_WITH_SYMBOL,
} from './segments-constants';
import {
  book6SymbolCharacterMap,
  book6WithSymbolMap,
  book6WithOutSymbolMap,
} from './segments-maps';

export const applyLMhero = (
  _str: string,
  symbols: number[],
  hairStyle: IBookHiraganaData['hairStyle'],
  glasses: IBookHiraganaData['glasses'],
  matcher = /{{LMhero:\d*}}/g,
) => {
  const LMheros = _str.match(matcher);

  if (!LMheros) return _str;

  const hero = hairStyle.match(/H\d*$/g)?.[0];

  if (!hero) {
    console.error('hero not found in', hairStyle, 'by /H\\d*$/g');
    return _str;
  }

  const digit = +(hero.match(/\d/)?.[0] || '');
  const replacement = glasses
    ? `-${hero.replace(`${digit}`, `${digit + 1}`)}`
    : `-${hero}`;

  let str = _str;

  LMheros.forEach((LMR) => {
    const [_, _symbol] = LMR.replace(/({{|}})/g, '').split(':');

    const symbol = Number(_symbol);

    if (Number.isNaN(symbol)) {
      console.error('symbol is not a number');
      return;
    }

    str = str.replace(LMR, symbols.includes(symbol) ? `${replacement}` : '');
  });

  return str;
};

export const processLMhero = (
  {
    hairStyle,
    heroName,
    glasses,
  }: Pick<IBookHiraganaData, 'hairStyle' | 'heroName' | 'glasses'>,
  objects: ImageElement[],
) => {
  const symbols = book6SymbolsMapper(heroName);
  return objects.map(({ imageURL, imagePDFURL, ...rest }) => {
    const obj: ImageElement = {
      ...rest,
      imageURL: applyLMhero(imageURL, symbols, hairStyle, glasses),
    };

    if (imagePDFURL)
      obj.imagePDFURL = applyLMhero(imagePDFURL, symbols, hairStyle, glasses);

    return obj;
  });
};

export const applyLMR = (_str: string, symbols: number[]) => {
  const LMRs = _str.match(/{{LMR:\d*}}/g);

  if (!LMRs) return _str;

  let str = _str;

  LMRs.forEach((LMR) => {
    const [_, _symbol] = LMR.replace(/({{|}})/g, '').split(':');

    const symbol = Number(_symbol);

    if (Number.isNaN(symbol)) {
      console.error('symbol is not a number');
      return;
    }

    str = str.replace(LMR, symbols.includes(symbol) ? 'L-M' : 'L-R');
  });

  return str;
};

export const processLMR = (
  { heroName }: Pick<IBookHiraganaData, 'heroName'>,
  objects: ImageElement[],
) => {
  const symbols = book6SymbolsMapper(heroName);
  return objects.map(({ imageURL, imagePDFURL, ...rest }) => {
    const obj: ImageElement = {
      ...rest,
      imageURL: applyLMR(imageURL, symbols),
    };

    if (imagePDFURL) obj.imagePDFURL = applyLMR(imagePDFURL, symbols);

    return obj;
  });
};

export const applyHero = (
  _str: string,
  hairStyle: IBookHiraganaData['hairStyle'],
  glasses: IBookHiraganaData['glasses'],
  matcher = /{{hero}}/g,
) => {
  const keys = _str.match(matcher);

  if (!keys) return _str;

  const hero = hairStyle.match(/H\d*$/g)?.[0];

  if (!hero) {
    console.error('hero not found in', hairStyle, 'by /H\\d*$/g');
    return 'assets/images/mock/questionmark.png';
  }

  let str = _str;
  // eslint-disable-next-line no-unsafe-optional-chaining
  const digit = +(hero.match(/\d/)?.[0] || 0);
  const replacement = glasses
    ? `-${hero.replace(`${digit}`, `${digit + 1}`)}`
    : `-${hero}`;

  keys.forEach((key) => {
    str = str.replace(key, replacement);
  });

  return str;
};

export const processHero = (
  { hairStyle, glasses }: Pick<IBookHiraganaData, 'hairStyle' | 'glasses'>,
  objects: ImageElement[],
) =>
  objects.map(({ imageURL, imagePDFURL, ...rest }) => {
    const obj: ImageElement = {
      ...rest,
      imageURL: applyHero(imageURL, hairStyle, glasses),
    };

    if (imagePDFURL)
      obj.imagePDFURL = applyHero(imagePDFURL, hairStyle, glasses);

    return obj;
  });

export const getKidstitle = (kunChan: IBookHiraganaData['kunChan']) =>
  kunChan === INPUT_VALUE_KUN_CHAN_NASHI
    ? ''
    : KUNCHAN_VALUE_TO_LABEL_MAP[kunChan];

export const applyKidstitle = (
  _str: string,
  kunChan: IBookHiraganaData['kunChan'],
  key = KEY_KIDS_TITLE,
) => {
  const keys = _str.match(new RegExp(key, 'g'));

  if (!keys) return _str;

  let str = _str;

  keys.forEach((_key) => {
    str = str.replace(_key, getKidstitle(kunChan));
  });

  return str;
};

export const applySymbol = (_str: string, symbol: number, key: string) => {
  const keys = _str.match(new RegExp(key, 'g'));

  if (!keys) return _str;

  let str = _str;

  keys.forEach((_key) => {
    str = str.replace(_key, book6SymbolsMapperMapValues[symbol] || '');
  });

  return str;
};

export const applySymbolCharacter = (
  _str: string,
  symbol: number,
  key: string,
) => {
  const keys = _str.match(new RegExp(key, 'g'));

  if (!keys) return _str;

  let str = _str;

  keys.forEach((_key) => {
    str = str.replace(
      _key,
      book6SymbolCharacterMap.get(book6SymbolsMapperMapValues[symbol] || '') ||
        '',
    );
  });

  return str;
};

export const applyWithSymbol = (
  _str: string,
  symbol: number,
  key = KEY_WITH_SYMBOL,
) => {
  const keys = _str.match(new RegExp(key, 'g'));

  if (!keys) return _str;

  let str = _str;

  keys.forEach((_key) => {
    str = str.replace(
      _key,
      book6WithSymbolMap.get(book6SymbolsMapperMapValues[symbol] || '') || '',
    );
  });

  return str;
};

export const applyWithoutSymbol = (
  _str: string,
  symbol: number,
  key = KEY_WITHOUT_SYMBOL,
) => {
  const keys = _str.match(new RegExp(key, 'g'));

  if (!keys) return _str;

  let str = _str;

  keys.forEach((_key) => {
    str = str.replace(
      _key,
      book6WithOutSymbolMap.get(book6SymbolsMapperMapValues[symbol] || '') ||
        '',
    );
  });

  return str;
};

export const symbolsIndexToString = (symbol: number) =>
  `${symbol + 1}`.padStart(2, '0');
