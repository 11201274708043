import { Component } from '@angular/core';
import type { TBookDescription } from '@studiobuki/shared/dist/data/books/desicription/types';
import { EBookDescriptionTextItemType } from '@studiobuki/shared/dist/data/books/desicription/types';
import { MediaService } from '@studiobuki/web-core/lib/media';

@Component({
  templateUrl: './about-page.component.html',
  styleUrls: ['./about-page.component.scss'],
})
export class AboutPageComponent {
  public readonly descriptionData: TBookDescription = [
    {
      imgSrc: 'assets/images/about-img1.png',
      text: [
        {
          type: EBookDescriptionTextItemType.caption,
          text: /* html */ `<span style="color: var(--buki-c-accent);font-family: ZenMaruGothic">私たちについて</span>`,
        },
        {
          type: EBookDescriptionTextItemType.regular,
          text: '私たちフレーベル館は、1907年4月21日に創業し、それから110年以上にわたり子どもたちの健やかな成長と、子どもたちを取り巻く大人たちの豊かな時間を大切にしたいと願い続けてきました。',
        },
        {
          type: EBookDescriptionTextItemType.regular,
          text: 'その中で「アンパンマン」や「ウォーリーをさがせ！」といったさまざまな絵本を発刊し、「初めての本との出会い」のなかで、子どもたちの“楽しい！”や“知りたい！”という好奇心や知性を育んできました。子どもが育つことは、未来が育つこと。私たちは子どもたちとその未来を育み、それを支える子育てに関わる全ての大人たちに寄り添っていきたいと考えています。',
        },
      ],
    },
    {
      imgSrc: 'assets/images/about-img2.png',
      text: [
        {
          type: EBookDescriptionTextItemType.caption,
          text: /* html */ `<span style="color: var(--buki-c-accent-2);font-family: ZenMaruGothic">子どもたちとのかけがえのない時間を</span>`,
        },
        {
          type: EBookDescriptionTextItemType.regular,
          text: '子どもたちがいきいきと、その自由な発想と個性を伸ばしていくことは何よりも大切なこと。それと同じくらい、子どもたちのそばに寄り添い、毎日の成長を見守っている大人たちのことも大切にしたい、と私たちは考えます。大人たちの笑顔が、喜びが、子どもたちの成長の大きな糧となると信じているから。',
        },
        {
          type: EBookDescriptionTextItemType.regular,
          text: '健やかに育つ子どもたちと、それをあたたかく見守る大人たちが、一緒に育んでいく時間。これは何ものにも代えがたい、かけがえのない時間です。私たちはそういった、子どもたちと大人たちがいっしょに過ごす、かけがえのない時間をいつまでも提供していきたいと考えています。',
        },
      ],
    },
    {
      imgSrc: 'assets/images/about-img3.png',
      text: [
        {
          type: EBookDescriptionTextItemType.caption,
          text: /* html */ `<span style="color: var(--buki-c-accent-3);font-family: ZenMaruGothic">特別な体験を大好きな人と</span>`,
        },
        {
          type: EBookDescriptionTextItemType.regular,
          text: 'パーソナライズ絵本は、毎日の忙しい中でも子どもとの時間は大切にしたいという、みなさまの思いを叶えられる特別な贈り物です。',
        },
        {
          type: EBookDescriptionTextItemType.regular,
          text: 'パーソナライズ絵本では、お話の中に子どもの名前を登場させたり、写真や伝えたいメッセージを入れたりと、好きなようにカスタマイズが可能です。読者である子どもたち自身を主人公にした、世界で１冊だけの絵本をプレゼントとして贈ることができます。',
        },
        {
          type: EBookDescriptionTextItemType.regular,
          text: '自分が登場する、自分だけの絵本を読むことは、子どもたちにとってとても価値のある経験になります。そしてそれを大好きな人と共有することで、つながりをより一層深めることができるのです。',
        },
        {
          type: EBookDescriptionTextItemType.regular,
          text: '世界に１冊だけの絵本を一緒に楽しむ、その時間は子どもにとっても大人にとっても、いつも読んでいる絵本とはまたひと味違った、特別な体験になるでしょう。',
        },
      ],
    },
  ];

  constructor(public media: MediaService) {}
}
