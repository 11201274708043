import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Logger } from '@studiobuki/shared/dist/logger';
import { scrollToSelector } from 'src/utils';

const log = new Logger('InfoComponent');

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoComponent {
  // constructor() {}

  public onButtonClick(): void {
    scrollToSelector('lib-book-form');
  }
}
