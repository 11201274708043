import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-section-footer-banners',
  templateUrl: './section-footer-banners.component.html',
  styleUrls: ['./section-footer-banners.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SectionFooterBannersComponent {

}
