<div
  class="wrap"
  (click)="onBookClick()"
  (mouseenter)="onMouseenter()"
  (mouseleave)="onMouseleave()"
>
  <div class="wrap__container">
    <div class="container">
      <div class="container__caption">
        <h4 class="caption">
          『<span [innerHTML]="caption | safe : 'html'"></span>』ギフトコード
        </h4>
      </div>
      <div class="container__content">
        <div class="content">
          <div class="content__tick">
            <app-tick
              [active]="selected"
              [hover]="(hover$ | async) || false"
            ></app-tick>
          </div>
          <div class="content__photo">
            <app-photo
              class="photo"
              [src]="photo"
              [alt]="caption"
            ></app-photo>
          </div>
          <div class="content__description">
            <div class="description">
              <div
                *ngIf="!(media.isLtMd$ | async)"
                class="description__summary"
              >
                <p class="summary">{{ summary }}</p>
              </div>
              <div class="description__notice-points">
                <div class="notice-points">
                  <div
                    class="notice-points__item"
                    *ngFor="let point of notice.points"
                  >
                    <div class="notice-point">
                      <img
                        class="notice-point__img"
                        [src]="point.imgSrc"
                        alt="#"
                      />
                      <p class="notice-point__txt">
                        {{ point.caption }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        *ngIf="selected"
        @slideY
        class="container__toggle"
      >
        <div class="toggle-wrap">
          <div
            class="toggle-wrap__item"
            *ngFor="let c of coversArr"
          >
            <lib-product-radio-card
              [active]="c.id === (cover$ | async)"
              [imgSrc]="c.image"
              [caption]="c.name"
              [summary]="c.summary"
              [price]="includeShippingToBookPrice(c.price, shippingIncluded)"
              [discount]="discount$ | async"
              [shippingDiscount]="shippingDiscount$ | async"
              (radioCardClick)="onCoverClick(c.id)"
            ></lib-product-radio-card>
          </div>
        </div>
      </div>
      <div
        *ngIf="!selected"
        @slideY
        class="container__price"
      >
        <div class="price-wrap">
          <lib-price
            class="price-wrap__item"
            [price]="minPrice.value"
            [vague]="true"
            [shippingIncluded]="minPrice.shippingIncluded"
            [currency]="minPrice.currency"
            [discount]="discount$ | async"
            [shippingDiscount]="shippingDiscount$ | async"
            [boldPrice]="true"
          ></lib-price>
        </div>
      </div>
    </div>
  </div>
</div>
